<template>
    <div data-canny />
</template>
  
<script>
    export default {
        mounted() {
            window.location.href = 'https://noodzly.canny.io/feature-requests';
        }
    }
</script>